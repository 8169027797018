import { CountryComparisonType } from "types/compare-situation.types";
import { compareSituations } from "./lists";

export const mapCountryComparison = (
  comparisonCountry1: CountryComparisonType,
  comparisonCountry2: CountryComparisonType
) => {
  return compareSituations.map((situation) => {
    switch (situation.name) {
      case "population":
        situation.country1.number = comparisonCountry1.youthPopulation;
        situation.country2.number = comparisonCountry2.youthPopulation;
        situation.country1.percentage = comparisonCountry1.youthPercentage;
        situation.country2.percentage = comparisonCountry2.youthPercentage;
        break;
      case "employed":
        situation.country1.number =
          comparisonCountry1.employmentBreakdown.totalEmployed;
        situation.country2.number =
          comparisonCountry2.employmentBreakdown.totalEmployed;
        situation.country1.percentage =
          comparisonCountry1.employmentBreakdown.employedPercentage;
        situation.country2.percentage =
          comparisonCountry2.employmentBreakdown.employedPercentage;
        situation.openSituations = [
          {
            name: "services.title",
            country1: {
              id: 0,
              number:
                comparisonCountry1.sectors.find(
                  (s) => s.serviceName === "Services"
                )?.number || 0,
              percentage: comparisonCountry1.sectors.find(
                (s) => s.serviceName === "Services"
              )?.percentage,
            },
            country2: {
              id: 0,
              number:
                comparisonCountry2.sectors.find(
                  (s) => s.serviceName === "Services"
                )?.number || 0,
              percentage: comparisonCountry2.sectors.find(
                (s) => s.serviceName === "Services"
              )?.percentage,
            },
          },
          {
            name: "agriculture.title",
            country1: {
              id: 1,
              number:
                comparisonCountry1.sectors.find(
                  (s) => s.serviceName === "Agriculture"
                )?.number || 0,
              percentage: comparisonCountry1.sectors.find(
                (s) => s.serviceName === "Agriculture"
              )?.percentage,
            },
            country2: {
              id: 1,
              number:
                comparisonCountry2.sectors.find(
                  (s) => s.serviceName === "Agriculture"
                )?.number || 0,
              percentage: comparisonCountry2.sectors.find(
                (s) => s.serviceName === "Agriculture"
              )?.percentage,
            },
          },
          {
            name: "industry.title",
            country1: {
              id: 2,
              number:
                comparisonCountry1.sectors.find(
                  (s) => s.serviceName === "Industry"
                )?.number || 0,
              percentage: comparisonCountry1.sectors.find(
                (s) => s.serviceName === "Industry"
              )?.percentage,
            },
            country2: {
              id: 2,
              number:
                comparisonCountry2.sectors.find(
                  (s) => s.serviceName === "Industry"
                )?.number || 0,
              percentage: comparisonCountry2.sectors.find(
                (s) => s.serviceName === "Industry"
              )?.percentage,
            },
          },
        ];
        break;
      case "working_poverty":
        situation.country1.number =
          comparisonCountry1.employmentBreakdown.povertyExtremelyPoorEmployed +
          comparisonCountry1.employmentBreakdown.povertyModeratelyPoorEmployed +
          comparisonCountry1.employmentBreakdown.povertyNotPoorEmployed;
        situation.country2.number =
          comparisonCountry2.employmentBreakdown.povertyExtremelyPoorEmployed +
          comparisonCountry2.employmentBreakdown.povertyModeratelyPoorEmployed +
          comparisonCountry2.employmentBreakdown.povertyNotPoorEmployed;
        situation.country1.percentage =
          comparisonCountry1.employmentBreakdown.employedPercentage;
        situation.country2.percentage =
          comparisonCountry2.employmentBreakdown.employedPercentage;
        situation.openSituations = [
          {
            name: "extremelyPoorTitle",
            country1: {
              id: 1,
              number:
                comparisonCountry1.employmentBreakdown
                  .povertyExtremelyPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry1.employmentBreakdown
                    .povertyExtremelyPoorEmployed /
                    comparisonCountry1.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
            country2: {
              id: 1,
              number:
                comparisonCountry2.employmentBreakdown
                  .povertyExtremelyPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry2.employmentBreakdown
                    .povertyExtremelyPoorEmployed /
                    comparisonCountry2.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
          },
          {
            name: "moderatelyPoorTitle",
            country1: {
              id: 2,
              number:
                comparisonCountry1.employmentBreakdown
                  .povertyModeratelyPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry1.employmentBreakdown
                    .povertyModeratelyPoorEmployed /
                    comparisonCountry1.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
            country2: {
              id: 2,
              number:
                comparisonCountry2.employmentBreakdown
                  .povertyModeratelyPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry2.employmentBreakdown
                    .povertyModeratelyPoorEmployed /
                    comparisonCountry2.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
          },
          {
            name: "notPoorTitle",
            country1: {
              id: 3,
              number:
                comparisonCountry1.employmentBreakdown.povertyNotPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry1.employmentBreakdown
                    .povertyNotPoorEmployed /
                    comparisonCountry1.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
            country2: {
              id: 3,
              number:
                comparisonCountry2.employmentBreakdown.povertyNotPoorEmployed,
              percentage: Number(
                (
                  (comparisonCountry2.employmentBreakdown
                    .povertyNotPoorEmployed /
                    comparisonCountry2.employmentBreakdown.totalEmployed) *
                  100
                ).toFixed(2)
              ),
            },
          },
        ];
        break;
      case "formal_jobs":
        situation.country1.number =
          comparisonCountry1.employmentBreakdown.totalFormalJobs;
        situation.country1.percentage =
          comparisonCountry1.employmentBreakdown.formalJobsPercentage;
        situation.country2.number =
          comparisonCountry2.employmentBreakdown.totalFormalJobs;
        situation.country2.percentage =
          comparisonCountry2.employmentBreakdown.formalJobsPercentage;
        break;
      case "student":
        situation.country1.number =
          comparisonCountry1.populationBreakDown.totalStudent;
        situation.country1.percentage =
          comparisonCountry1.populationBreakDown.studentPercentage;
        situation.country2.number =
          comparisonCountry2.populationBreakDown.totalStudent;
        situation.country2.percentage =
          comparisonCountry2.populationBreakDown.studentPercentage;
        break;
      case "inactive":
        situation.country1.number =
          comparisonCountry1.populationBreakDown.totalInactive;
        situation.country1.percentage =
          comparisonCountry1.populationBreakDown.inactivePercentage;
        situation.country2.number =
          comparisonCountry2.populationBreakDown.totalInactive;
        situation.country2.percentage =
          comparisonCountry2.populationBreakDown.inactivePercentage;
        break;
      case "unemployed":
        situation.country1.number =
          comparisonCountry1.populationBreakDown.totalUnemployed;
        situation.country1.percentage =
          comparisonCountry1.populationBreakDown.unemployedPercentage;
        situation.country2.number =
          comparisonCountry2.populationBreakDown.totalUnemployed;
        situation.country2.percentage =
          comparisonCountry2.populationBreakDown.unemployedPercentage;
        break;
      case "urban_areas":
        situation.country1.number =
          comparisonCountry1.populationBreakDown.totalUnemployedUrban;
        situation.country1.percentage =
          comparisonCountry1.populationBreakDown.unemployedUrbanPercentage;
        situation.country2.number =
          comparisonCountry2.populationBreakDown.totalUnemployedUrban;
        situation.country2.percentage =
          comparisonCountry2.populationBreakDown.unemployedUrbanPercentage;
        break;
      default:
        break;
    }
    return situation;
  });
};
