import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Legend from "components/Main/Employment/Legend";
import Tooltip from "components/Main/Employment/Tooltip";
import Country from "components/Main/Employment/Country";
import { TooltipInfo } from "types/tooltip.types";
import Filters from "components/Main/Employment/Filters";
import { GeoJSON, MapContainer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import useLeaflet from "hooks/useLeaflet";
import {
  countryStyle,
  centerMap,
  defaultMapZoom,
  isTigrayAfter2020,
  areSubnationalsAvailable,
} from "utils/leaflet";
import back from "assets/images/map/back.svg";
import arrowLeft from "assets/images/arrowLeft.svg";
import useFetch from "hooks/useFetch";
import kenya from "data/subNationals/Kenya.json";
import rwanda from "data/subNationals/Rwanda.json";
import ghana from "data/subNationals/Ghana.json";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import useWindowResize from "hooks/useWindowResize";
import arrowOpen from "assets/images/arrowOpen.svg";
import { countries } from "utils/lists";
import ShareBtn from "components/Share";
import { loadEthiopianGeoJson } from "utils/ethiopianRegions";
import useToggle from "hooks/useToggle";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Map = () => {
  const {
    country,
    setCountryFilters,
    year,
    regionMapSection,
    ages,
    setFilters,
    gender,
    mapCoords,
    status,
    mapEmployedSector,
  } = useAfricanYouth();
  const { handleToggleState, toggleState } = useToggle();
  const { isDesktop, isDevice } = useWindowResize();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [mapRef, setMapRef] = useState<any>(null);
  const geoJsonLayer = useRef<any>(null);
  const { handleResize } = useLeaflet(mapRef);
  const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | null>(null);
  const geoJsonDataRef = useRef<any>(null);

  const { data } = useFetch(`${urls.API_HOST_URL}map?year=${year}`);

  const { data: regionsData } = useFetch(
    `${urls.API_HOST_URL}map/regions?year=${year}&countryCode=${getCountryId(
      country.employment_section!
    )}`,
    !areSubnationalsAvailable(mapCoords, country.employment_section!)
  );

  const updatedMapData = useMemo(
    () =>
      mapCoords.map((country: any) => {
        const findedItem = data?.countryDatas?.find(
          ({ countryCode }: { countryCode: string }) =>
            countryCode === country.iso3c
        );

        return { ...findedItem, ...country };
      }),
    [data?.countryDatas, mapCoords]
  );

  useEffect(() => {
    window.addEventListener("scroll", () => setTooltipInfo(null));
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", () => setTooltipInfo(null));
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const zoomToCountry = ({ target }: any) => {
    if (target) {
      if (isTigrayAfter2020(target.feature.properties.name, Number(year)))
        return;

      if (
        !countries.some(
          (option) => option.name === target.feature.properties.name
        )
      ) {
        setFilters({
          value: target.feature.properties.name,
          name: "regionMapSection",
        });
      } else {
        setCountryFilters({
          value: target.feature.properties.name,
          name: "employment_section",
        });
      }

      setTimeout(() => {
        mapRef.target.invalidateSize();
        mapRef.target.flyToBounds(target.getBounds());
      }, 500);
    }
  };

  const handleMapReset = (section?: string) => {
    setCountryFilters({
      value: null,
      name: section,
    });
    if (section !== "regionMapSection") {
      setTimeout(() => {
        mapRef.target.invalidateSize();
        geoJsonLayer.current.clearLayers().addData(updatedMapData);
        mapRef.target.fitBounds(geoJsonLayer.current.getBounds());
      }, 500);
    }
  };

  const highlightFeature = (layer: any) =>
    layer.target.feature.countryCode && setTooltipInfo(layer);

  const resetHighlight = () => setTooltipInfo(null);

  const mapInteractions = (_: any, layer: any) => {
    layer.on({
      mouseout: resetHighlight,
      mouseover: highlightFeature,
      click: zoomToCountry,
    });
  };

  const handleRegionsData = async () => {
    if (regionsData && geoJsonDataRef.current) {
      geoJsonDataRef.current = geoJsonDataRef.current.map((region: any) => {
        const matchingRegion = regionsData?.countryDatas.find(
          ({ countryCode }: { countryCode: string }) =>
            countryCode === region.properties.name
        );

        return {
          ...region,
          ...region.properties,
          ...matchingRegion,
        };
      });
    }
  };

  const updateTigrayStyle = () => {
    const tigrayLayer: any = Object.values(geoJsonLayer.current._layers).find(
      ({ feature }: any) => feature.properties.name === "Tigray"
    );

    if (tigrayLayer) {
      const shouldBeGrey = isTigrayAfter2020("Tigray", Number(year));
      tigrayLayer.setStyle({
        fillColor: shouldBeGrey
          ? theme`colors.grey.200`
          : theme`colors.orange.300`,
        fillOpacity: 1,
        weight: regionMapSection === "Tigray" ? 5 : 0.4,
        color: theme`colors.grey.600`,
      });
    }
  };

  const setMapLayers = useCallback(async () => {
    geoJsonLayer.current.clearLayers();

    let countryGeoJsonData;

    if (toggleState.areMapSubNationalsActive && country.employment_section) {
      switch (country.employment_section) {
        case "Kenya":
          countryGeoJsonData = kenya.features;
          break;
        case "Rwanda":
          countryGeoJsonData = rwanda.features;
          break;
        case "Ghana":
          countryGeoJsonData = ghana.features;
          break;
        case "Ethiopia":
          try {
            const data = await loadEthiopianGeoJson(year);
            countryGeoJsonData = data.features;
          } catch (error) {
            console.error("Error loading Ethiopian GeoJSON data:", error);
            return;
          }
          break;
        default:
          countryGeoJsonData = updatedMapData;
          break;
      }

      geoJsonDataRef.current = countryGeoJsonData;
      await handleRegionsData();
    } else {
      geoJsonDataRef.current = updatedMapData;
    }

    geoJsonLayer.current.addData(geoJsonDataRef.current);

    updateTigrayStyle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    country.employment_section,
    toggleState.areMapSubNationalsActive,
    updatedMapData,
    regionsData,
    year,
  ]);

  useEffect(() => {
    const updateMapLayers = async () => {
      if (mapRef?.target && geoJsonLayer?.current) {
        await setMapLayers();

        const target: any = Object.values(geoJsonLayer.current._layers).find(
          ({ feature }: any) =>
            feature.properties.name === country.employment_section
        );

        if (target) {
          zoomToCountry({ target });
        }

        getStyle();
        updateTigrayStyle();
        mapRef.target.invalidateSize();
        mapRef.target.fitBounds(geoJsonLayer.current.getBounds());
      }
    };

    updateMapLayers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    country.employment_section,
    data,
    year,
    toggleState.areMapSubNationalsActive,
  ]);

  const getStyle = () => {
    const activeSubregion: any = Object.values(mapRef.target._targets).filter(
      ({ feature }: any) =>
        feature?.properties.name ===
        (regionMapSection || country.employment_section)
    );

    activeSubregion.forEach((target: any) => {
      const shouldBeGrey = isTigrayAfter2020(
        target.feature.properties.name,
        Number(year)
      );

      target.setStyle({
        fillColor: shouldBeGrey
          ? theme`colors.grey.200`
          : target.feature.subnationals
          ? theme`colors.orange.600`
          : theme`colors.orange.300`,
        fillOpacity: 1,
        weight: 5,
        color: theme`colors.grey.600`,
      });
    });

    return countryStyle(
      activeSubregion,
      isTigrayAfter2020(
        activeSubregion[0]?.feature.properties.name,
        Number(year)
      )
    );
  };

  return (
    <section
      id="Employment"
      css={[
        tw`grid grid-cols-[33% 67%] md:flex md:flex-col md:pb-8 md:gap-6`,
        country.employment_section && tw`grid-cols-[repeat(3, 1fr)]`,
      ]}>
      <article tw="max-w-[38rem] md:max-w-none flex flex-col p-[1.5rem 4rem] sm:p-[1rem] gap-[1rem]">
        <h2 tw="font-semiBold text-xl text-grey-600">
          {t("status_breakdown_title")}
        </h2>
        <p tw="text-grey-500 text-sm w-full pb-[0.5rem]">
          {t("status_breakdown_description")}
        </p>
        <div>
          <Filters
            zoomToCountry={zoomToCountry}
            handleMapReset={handleMapReset}
            handleToggleState={handleToggleState}
            areMapSubNationalsActive={toggleState.areMapSubNationalsActive}
          />
          {country.employment_section && (
            <button
              onClick={() => setIsMapOpen((prevIsMapOpen) => !prevIsMapOpen)}
              tw="mt-4 md:flex items-center text-orange-700 uppercase font-semiBold text-sm gap-[0.5rem] hidden">
              {!isMapOpen ? "Show Details" : "Show Map"}
              <img src={arrowOpen} alt="Arrow open" />
            </button>
          )}
        </div>
        {country.employment_section && (
          <>
            <div tw="flex flex-col gap-[1rem] w-full sm:flex-wrap">
              <label tw="text-grey-500 leading-[0.938rem] text-sm">
                {t("filters")}
              </label>
              <div tw="flex gap-[1rem]">
                <button
                  onClick={() => {
                    setFilters({
                      value: true,
                      name: "isFiltersDialogOpen",
                    });
                    setFilters({
                      value: ["AGE", "GENDER"],
                      name: "filterListForSection",
                    });
                  }}
                  tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
                  {`${ages.from ? ages.from : 15}-${
                    ages.to ? ages.to : 35
                  } years`}
                </button>
                <button
                  onClick={() => {
                    setFilters({
                      value: true,
                      name: "isFiltersDialogOpen",
                    });
                    setFilters({
                      value: ["AGE", "GENDER"],
                      name: "filterListForSection",
                    });
                  }}
                  tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
                  {t(gender)}
                </button>
              </div>
            </div>
            {country.employment_section && !isDevice && (
              <ShareBtn
                section="Employment"
                country={country.employment_section!}
                status={status}
                sector={mapEmployedSector!}
                region={regionMapSection!}
                ages={{ from: ages.from!, to: ages.to! }}
              />
            )}
          </>
        )}
      </article>
      <article tw="w-full relative flex justify-end items-center">
        {data && (
          <>
            {!isDesktop && isMapOpen && (
              <div tw="w-full bg-white">
                <Country />
              </div>
            )}
            <div tw="w-full" className={isMapOpen ? "mobile-hide-map" : ""}>
              <MapContainer
                whenReady={setMapRef as any}
                tw="bg-grey-100 h-[35rem] w-full relative"
                css={[country.employment_section && tw`w-[31.625rem]`]}
                center={centerMap}
                zoom={defaultMapZoom}
                minZoom={defaultMapZoom}
                zoomControl={false}
                doubleClickZoom={false}
                trackResize={false}
                touchZoom={false}
                scrollWheelZoom={false}>
                <ZoomControl />
                <GeoJSON
                  ref={geoJsonLayer}
                  data={updatedMapData as any}
                  style={(country) => {
                    getStyle();
                    return countryStyle(
                      country,
                      isTigrayAfter2020(country!.properties?.name, Number(year))
                    );
                  }}
                  onEachFeature={mapInteractions}
                />
                {country.employment_section && (
                  <button
                    tw="z-[999] absolute left-[1rem] top-[1rem]"
                    onClick={() => handleMapReset()}>
                    <img alt="Back button" src={back} />
                  </button>
                )}
                {!country.employment_section && <Legend />}
              </MapContainer>
            </div>
          </>
        )}
        {tooltipInfo && <Tooltip tooltipInfo={tooltipInfo as any} />}
      </article>
      {isDesktop && country.employment_section && (
        <article
          tw="lg:fixed lg:overflow-y-scroll bg-white lg:top-0 lg:right-0 lg:left-0 lg:bottom-0 z-[1000] lg:z-[1001]"
          className="scrollable">
          <button
            tw="hidden lg:flex gap-[0.5rem] p-[1.5rem 4rem 0] sm:p-[1rem] items-center text-orange-700 uppercase font-semiBold"
            onClick={() => handleMapReset()}>
            <img src={arrowLeft} alt="Arrow left" />
            {t("employment_breakdown_title")}
          </button>
          <div tw="lg:block hidden p-[1.5rem 4rem] sm:p-[0 1rem 1rem]">
            <Filters
              zoomToCountry={zoomToCountry}
              handleMapReset={handleMapReset}
            />
          </div>
          <Country />
        </article>
      )}
      {country.employment_section && isDevice && (
        <ShareBtn
          section="Employment"
          country={country.employment_section!}
          status={status}
          sector={mapEmployedSector!}
          region={regionMapSection!}
          ages={{ from: ages.from!, to: ages.to! }}
        />
      )}
    </section>
  );
};

export default Map;
