import useWindowResize from "hooks/useWindowResize";
import { areSubnationalsAvailable } from "utils/leaflet";
import useAutocomplete from "hooks/useAutocomplete";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles } from "utils/autoComplete";
import ToggleSwitch from "components/ToggleSwitch";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { statusList, countries } from "utils/lists";
import { getSubnationals } from "utils/filters";
import useToggle from "hooks/useToggle";
import { useMemo } from "react";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Filters = () => {
  const {
    ages,
    country,
    mobileModalDropdown,
    setMobileModalDropdown,
    setFilters,
    regionGendersSection,
    genderStatus,
    year,
    mapCoords,
  } = useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { handleToggleState, toggleState } = useToggle();
  const { isDesktop } = useWindowResize();

  const subNationalsList = useMemo(
    () => getSubnationals(country.sectors_section!, year)!,
    [country.sectors_section, year]
  );

  const memoCountryInput = useMemo(
    () => (
      <div tw="mt-2 w-full">
        <Autocomplete
          readOnly={isDesktop ? false : true}
          clearIcon={<ClearIcon />}
          autoHighlight
          options={countries}
          onChange={(_, value, reason) =>
            handleAutocompleteChange(value?.name!, reason, "sectors_section")
          }
          value={
            country.sectors_section
              ? { name: country.sectors_section }
              : (null as any)
          }
          tw="w-full"
          sx={AutoCompleteStyles}
          getOptionLabel={(option) => option.name}
          renderOption={(props: any, option: Country) => (
            <button
              {...props}
              style={{
                color: theme`colors.grey.700`,
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: 700,
                gap: "0.65rem",
                width: "100%",
                textAlign: "left",
              }}>
              {option.file_url ? (
                <img
                  loading="lazy"
                  width="16"
                  src={option.file_url}
                  alt={option.name}
                />
              ) : (
                <span style={{ width: "1rem" }}></span>
              )}
              {option.name}
            </button>
          )}
          renderInput={(params: any) => {
            const activeCountryFlag = countries.find(
              ({ name }) => name === params.inputProps.value
            );

            return (
              <div
                tw="flex items-center relative"
                onClick={() =>
                  isDesktop
                    ? undefined
                    : setMobileModalDropdown({
                        value: !mobileModalDropdown.isMobileModalDropdownOpen,
                        name: "mobileModalDropdown",
                        section: "countries/sectors_section",
                      })
                }>
                {activeCountryFlag && (
                  <img
                    key={params.inputProps.value + "'s flag"}
                    loading="lazy"
                    width="24"
                    src={activeCountryFlag.file_url}
                    alt="Country flag"
                    tw="absolute left-[0.5rem] z-10"
                  />
                )}
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  sx={{
                    textAlign: "left",
                    paddingLeft: params.inputProps.value ? "2rem" : 0,
                    input: {
                      "&::placeholder": {
                        color: "#ADADAD !important",
                      },
                    },
                  }}
                  placeholder={t("type_or_select_a_region_placeholder")}
                />
              </div>
            );
          }}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.sectors_section, isDesktop]
  );

  const memoSubnationalInput = useMemo(
    () => (
      <div tw="pt-2">
        <Autocomplete
          readOnly={isDesktop ? false : true}
          clearIcon={<ClearIcon />}
          autoHighlight
          options={subNationalsList}
          onChange={(_, value, reason) =>
            handleAutocompleteChange(
              value?.name!,
              reason,
              "regionGendersSection"
            )
          }
          sx={AutoCompleteStyles}
          getOptionLabel={(option) => option.name}
          renderOption={(props: any, option: any) => (
            <button
              {...props}
              style={{
                color: theme`colors.grey.700`,
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: 700,
                gap: "0.65rem",
                width: "100%",
                textAlign: "left",
              }}>
              {t(option.name)}
            </button>
          )}
          renderInput={(params: any) => (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "regionGendersSection",
                      data: subNationalsList,
                    })
              }>
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_subregion_placeholder")}
              />
            </div>
          )}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionGendersSection, isDesktop, subNationalsList]
  );

  const memoStatusInput = useMemo(
    () => (
      <div tw="flex flex-col w-full">
        <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
          {t("status.title")}
        </label>
        <Autocomplete
          readOnly={isDesktop ? false : true}
          clearIcon={<ClearIcon />}
          autoHighlight
          options={statusList}
          onChange={(_, value, reason) =>
            handleAutocompleteChange(value?.name!, reason, "genderStatus")
          }
          value={
            genderStatus ? { name: t(`${genderStatus}.title`) } : (null as any)
          }
          sx={AutoCompleteStyles}
          getOptionLabel={(option) => option.name}
          renderOption={(props: any, option) => (
            <button
              {...props}
              style={{
                color: theme`colors.grey.700`,
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: 700,
                gap: "0.65rem",
                width: "100%",
                textAlign: "left",
              }}>
              <img
                loading="lazy"
                width="16"
                src={`assets/images/status/${t(`${option.name}.title`)}.svg`}
                alt={t(`${option.name}.title`)!}
              />
              {t(`${option.name}.title`)}
            </button>
          )}
          renderInput={(params: any) => (
            <div
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "genderStatus",
                    })
              }
              tw="flex justify-between items-center relative">
              {genderStatus && (
                <img
                  loading="lazy"
                  width="24"
                  src={`assets/images/status/${t(`${genderStatus}.title`)}.svg`}
                  alt="Status"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                }}
                placeholder={t("status.title")}
              />
            </div>
          )}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genderStatus, isDesktop]
  );

  return (
    <article tw="p-[1rem 0] sm:p-[0 1rem]">
      {areSubnationalsAvailable(mapCoords, country.sectors_section!) ? (
        <>
          <ToggleSwitch
            sectionName="areGendersSubNationalsActive"
            isActive={toggleState.areGendersSubNationalsActive}
            handleToggleState={handleToggleState}
          />
        </>
      ) : null}
      <div
        css={[
          tw`flex justify-start gap-[1rem] items-end md:flex-col md:items-start w-full mt-[1rem] md:m-0`,
          toggleState.areGendersSubNationalsActive && tw`xl:flex-col`,
        ]}>
        <div tw="w-full">
          <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
            {t("region")}
          </label>
          <div tw="flex gap-2 items-center">
            {memoCountryInput}
            <Warning countryName={country.sectors_section!} />
          </div>
        </div>
        {toggleState.areGendersSubNationalsActive &&
          areSubnationalsAvailable(mapCoords, country.sectors_section!) && (
            <div tw="w-full">
              <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
                {t("subregion")}
              </label>
              {memoSubnationalInput}
            </div>
          )}
        <div tw="flex gap-[1rem] w-full sm:flex-wrap">{memoStatusInput}</div>
        <div tw="flex flex-col gap-[1rem] w-full sm:flex-wrap md:gap-0">
          <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
            {t("filters")}
          </label>
          <div tw="flex gap-[1rem]">
            <button
              onClick={() => {
                setFilters({
                  value: true,
                  name: "isFiltersDialogOpen",
                });
                setFilters({
                  value: ["AGE", "GENDER", "JOBTYPE", "AREA"],
                  name: "filterListForSection",
                });
              }}
              tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
              {`${ages.from ? ages.from : 15}-${ages.to ? ages.to : 35} years`}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Filters;
