import { t } from "i18next";
import info from "assets/images/info.svg";
import arrowDown from "assets/images/openArrowDropDown.svg";
import useToggle from "hooks/useToggle";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import useWindowResize from "hooks/useWindowResize";
import { useAfricanYouth } from "context/africanYouth";
import {
  CompareSituationsProps,
  CountryComparisonType,
} from "types/compare-situation.types";
import { useEffect, useState } from "react";
import { mapCountryComparison } from "utils/countryComparison";
import { getCountryInfo } from "utils/countries";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Table = ({
  comparisonCountry1,
  comparisonCountry2,
}: {
  comparisonCountry1: CountryComparisonType;
  comparisonCountry2: CountryComparisonType;
}) => {
  const { setMobileModalDropdown, mobileModalDropdown, country } =
    useAfricanYouth();
  const [comparisonData, setComparisonData] =
    useState<CompareSituationsProps[]>();
  const { handleToggleState, toggleState } = useToggle();
  const { isDesktop } = useWindowResize();

  useEffect(() => {
    const comparisonData = mapCountryComparison(
      comparisonCountry1,
      comparisonCountry2
    );
    setComparisonData(comparisonData);
  }, [comparisonCountry1, comparisonCountry2]);

  if (!comparisonData) {
    return <></>;
  }

  return (
    <article tw="max-w-[70rem] m-[1rem auto]  w-full sm:border-t sm:border-none sm:border-t-grey-200 border border-grey-200 sm:rounded-none rounded-[0.5rem] sm:m-0">
      <div tw="sm:mt-[2rem] mt-[1rem] sm:px-[1rem] sm:border-t-grey-200 sm:border-t">
        <div tw="grid grid-cols-[repeat(3, 1fr)] justify-between items-center sm:max-w-[14.5rem] max-w-[20rem] m-auto py-[1rem] xl:max-w-[15rem]">
          <div tw="font-semiBold text-grey-500 text-sm items-end flex flex-col gap-[0.5rem]">
            {country.comparison_country_1_section && (
              <img
                key={country.comparison_country_1_section}
                loading="lazy"
                width={32}
                src={
                  getCountryInfo(country.comparison_country_1_section)!.file_url
                }
                alt={`${country.comparison_country_1_section} flag`}
              />
            )}
            <span>{t(country.comparison_country_1_section || "")}</span>
          </div>
          <span tw="flex items-center p-[1rem] rounded-[50%] uppercase border border-purple-200 w-[2rem] justify-center h-[2rem] text-purple-800 justify-self-center text-[0.688rem]">
            vs
          </span>
          <div tw="font-semiBold text-grey-500 text-sm items-start flex flex-col gap-[0.5rem]">
            {country.comparison_country_2_section && (
              <img
                key={country.comparison_country_2_section}
                loading="lazy"
                width={32}
                src={
                  getCountryInfo(country.comparison_country_2_section)!.file_url
                }
                alt={`${country.comparison_country_2_section} flag`}
              />
            )}
            <span>{t(country.comparison_country_2_section || "")}</span>
          </div>
        </div>
      </div>
      {comparisonData.map((situation) => (
        <div
          key={situation.name}
          tw="border-b border-b-grey-200 last-of-type:border-none"
          css={[
            situation.name === "unemployed" && tw`border-none`,
            situation.open ? tw`border-none` : null,
          ]}>
          <div tw="xxs:text-xs sm:text-sm gap-[1rem] grid grid-cols-[repeat(3, 1fr)] items-center justify-center lg:p-[2rem] p-[2rem 6rem] sm:p-4">
            <div tw="flex flex-col gap-[0.25rem] text-right ">
              <p>{(situation.country1?.number || 0).toLocaleString()}</p>
              <div
                tw="flex self-end h-[0.5rem] bg-green-600 rounded-tl rounded-bl"
                css={[
                  situation.name === "working_poverty" ||
                  situation.name === "formal_jobs" ||
                  situation.name === "urban_areas"
                    ? tw`bg-green-400`
                    : null,
                ]}
                style={{
                  width: `${situation.country1.percentage}%`,
                }}></div>
              {situation.name !== "working_poverty" && situation.country1.percentage && (
                <span tw="text-grey-500 text-[0.813rem]">
                  {situation.country1.percentage}%
                </span>
              )}

              {situation.name === "working_poverty" && (
                <span tw="text-grey-500 text-[0.813rem]">
                 100%
                </span>
              )}
            </div>
            <div tw="flex items-center justify-center gap-[0.5rem]">
              <div tw="flex flex-col items-center gap-[0.5rem] text-center ">
                <DynamicSvg iconName={situation.name} />
                <h3
                  tw="font-semiBold xxs:text-xs sm:text-sm text-[1.063rem] text-grey-500 lg:w-[12rem] md:w-[8rem] sm:w-[6.5rem] xxs:w-[4rem] flex justify-center capitalize"
                  css={[
                    situation.name === "working_poverty" ||
                    situation.name === "formal_jobs" ||
                    situation.name === "urban_areas"
                      ? tw`font-regular text-[0.9rem]`
                      : null,

                    situation.name === "working_poverty"
                      ? tw`max-w-[59px] text-center`
                      : null,
                  ]}>
                  {t(
                    situation.name === "population"
                      ? `${situation.name}.title`
                      : situation.name
                  )}
                </h3>
              </div>
              {situation.name === "working_poverty" ||
              situation.name === "formal_jobs" ? (
                <button
                  tw="relative w-[25px]"
                  className={isDesktop ? "group" : ""}
                  onClick={() =>
                    isDesktop
                      ? undefined
                      : setMobileModalDropdown({
                          value: !mobileModalDropdown.isMobileModalDropdownOpen,
                          name: "mobileModalDropdown",
                          section: "working_poverty_info",
                        })
                  }>
                  <img width={25} src={info} alt="Info" />
                  <span tw="absolute hidden group-hover: justify-center -top-2/4 right-0 translate-x-2/4 max-w-[max-content] bg-grey-500 rounded-[0.5rem] text-white p-[0.5rem] w-[15rem] -translate-y-full text-sm text-left z-10">
                    {t("working_poverty_info.text")}
                  </span>
                </button>
              ) : null}
            </div>
            <div tw="flex flex-col gap-[0.25rem] ">
              <p>{(situation.country2?.number || 0).toLocaleString()}</p>
             
              <div
                tw="flex self-start h-[0.5rem] bg-yellow-400 rounded-tr rounded-br"
                css={[
                  situation.name === "working_poverty" ||
                  situation.name === "formal_jobs" ||
                  situation.name === "urban_areas"
                    ? tw`bg-yellow-300`
                    : null,
                ]}
                style={{
                  width: `${situation.country2.percentage}%`,
                }}></div>
                 { situation.name !== "working_poverty" && situation.country2.percentage && (
                <span tw="text-grey-500 text-[0.813rem]">
                  {situation.country2.percentage}%
                </span>
                 )}
                 { situation.name === "working_poverty" && 
                  <span tw="text-grey-500 text-[0.813rem]">
                  100%
                </span>
                 }
            </div>
          </div>
          {situation.open && (
            <button
              tw="p-[0 1rem] hover:bg-grey-50 hover:rounded-[0.5rem] flex gap-[0.75rem] items-center max-w-max m-auto font-semiBold text-orange-700 text-[0.938rem] uppercase sm:text-xs sm:p-[0.5rem]"
              onClick={() => handleToggleState(`${situation.name}_comparison`)}>
              {(toggleState as any)[`${situation.name}_comparison`]
                ? t("close")
                : t("learn_more")}
              <img
                src={arrowDown}
                alt="Arrow"
                css={[
                  (toggleState as any)[`${situation.name}_comparison`] &&
                    tw`rotate-180`,
                ]}
              />
            </button>
          )}
          {situation.open &&
            situation.openSituations &&
            (toggleState as any)[`${situation.name}_comparison`] &&
            situation.openSituations.map(
              ({ name, country1, country2, open }) => (
                <div
                  key={name}
                  tw="xxs:text-xs sm:text-sm text-sm font-semiBold border-b border-b-[rgba(0, 0, 0, 0.12)] text-grey-600 sm:p-[1rem] last-of-type:border-b-0 bg-orange-50 max-w-[70rem]">
                  <div tw="gap-[1rem] grid grid-cols-[repeat(3, 1fr)] items-center justify-center lg:p-[2rem] p-[1rem 6rem] ">
                    <div tw="flex flex-col gap-[0.25rem] text-right ">
                      <p>{country1.number!.toLocaleString()}</p>
                      <div
                        tw="flex self-end h-[0.5rem] bg-green-600 rounded-tl rounded-bl"
                        css={[situation.open && tw`bg-green-400`]}
                        style={{
                          width: `${country1.percentage}%`,
                        }}></div>
                      {country1.percentage && (
                        <span tw="text-grey-500 text-[0.813rem]">
                          {country1.percentage}%
                        </span>
                      )}
                    </div>
                    <div tw="flex flex-col items-center gap-[0.5rem] text-center ">
                      <DynamicSvg iconName={name} />
                      <h3 tw="xxs:text-xs sm:text-sm text-center lg:w-[12rem] md:w-[8rem] sm:w-[6.5rem] xxs:w-[4rem]">
                        {t(name)}
                      </h3>
                    </div>
                    <div tw="flex flex-col gap-[0.25rem] ">
                      <p>{country2.number!.toLocaleString()}</p>
                      <div
                        tw="flex self-start h-[0.5rem] bg-yellow-400 rounded-tr rounded-br"
                        css={[open && tw`bg-yellow-300`]}
                        style={{
                          width: `${country2.percentage}%`,
                        }}></div>
                      {country2.percentage && (
                        <span tw="text-grey-500 text-[0.813rem]">
                          {country2.percentage}%
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      ))}
    </article>
  );
};

export default Table;
