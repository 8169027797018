import { Fragment } from "react";
import { t } from "i18next";
import Tooltip from "components/Main/Sectors/Tooltip";
import females from "assets/images/females.svg";
import males from "assets/images/males.svg";
import { SectorsDataTypes } from "types/sectors.types";
import { handleAgriculture } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const InnerSector = ({
    activeSummaryGender,
    handleActiveSummaryGender,
    sectorsData
}: {
    activeSummaryGender: {
        id: number;
        name: string;
        number: number;
        percentage: string;
    } | null;
    handleActiveSummaryGender: Function;
    sectorsData: SectorsDataTypes;
}) => (
    <div tw="flex w-full p-[1rem 0] overflow-x-auto" className="scrollable">
        {sectorsData.sectors.map(
            ({ name, population, percentage, genderPercentageMap }, i) => (
                <Fragment key={name}>
                    <div tw="w-full relative border-r border-r-grey-100 last-of-type:border-0 p-[1rem]">
                        {activeSummaryGender?.id === i && (
                            <Tooltip
                                activeSummaryGender={activeSummaryGender}
                            />
                        )}
                        <p tw="text-grey-600 font-semiBold">
                            {population.toLocaleString()}
                        </p>
                        <p tw="text-grey-500 text-[0.813rem] pb-[0.75rem]">
                            {percentage}%
                        </p>
                        <div tw="flex justify-center items-end gap-[0.3rem] h-[8rem]">
                            <button
                                tw="flex flex-col items-center h-[80%] justify-end	"
                                onMouseEnter={() =>
                                    handleActiveSummaryGender("males", {
                                        id: i,
                                        name: "Male",
                                        number: (
                                            population *
                                            (genderPercentageMap.male / 100)
                                        ).toFixed(),
                                        percentage: genderPercentageMap.male
                                    })
                                }
                                onMouseLeave={() =>
                                    handleActiveSummaryGender("males", null)
                                }
                            >
                                <span tw="text-[0.813rem] text-green-600 font-semiBold flex-grow-0">
                                    {genderPercentageMap.male}%
                                </span>
                                <span
                                    tw="block bg-green-600 w-[6rem] lg:w-[4rem] md:w-[2rem]  rounded-[0.25rem 0.25rem 0 0]"
                                    style={{
                                        flex: `0 0 ${genderPercentageMap.male}%`
                                    }}
                                ></span>
                                <img src={males} alt="Males" />
                            </button>
                            <button
                                tw="flex flex-col items-center h-[80%] justify-end	"
                                onMouseEnter={() =>
                                    handleActiveSummaryGender("females", {
                                        id: i,
                                        name: "Female",
                                        number: (
                                            population *
                                            (genderPercentageMap.female / 100)
                                        ).toFixed(),
                                        percentage: genderPercentageMap.female
                                    })
                                }
                                onMouseLeave={() =>
                                    handleActiveSummaryGender("females", null)
                                }
                            >
                                <span tw="text-[0.813rem] text-yellow-500 font-semiBold flex-grow-0">
                                    {genderPercentageMap.female}%
                                </span>
                                <span
                                    tw="block bg-yellow-500 w-[6rem] lg:w-[4rem] md:w-[2rem] rounded-[0.25rem 0.25rem 0 0]"
                                    style={{
                                        flex: `0 0 ${genderPercentageMap.female}%`
                                    }}
                                ></span>
                                <img src={females} alt="Females" />
                            </button>
                        </div>
                        <div tw="flex justify-center gap-[1rem] items-center sm:flex-col sm:gap-0 sm:mt-[1rem]">
                            <img
                                alt={name}
                                src={`assets/images/sectors/${handleAgriculture(
                                    name
                                )!
                                    .toLocaleLowerCase()
                                    .split(" ")
                                    .join("_")}.svg`}
                            />
                            <h4 tw="text-grey-600 text-sm py-[0.25rem] text-left sm:text-center">
                                {t(name)}
                            </h4>
                        </div>
                    </div>
                </Fragment>
            )
        )}
    </div>
);

export default InnerSector;
