import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { getCountryId } from "utils/countries";
import { getDisaggregated, getGender } from "utils/filters";
import { t } from "i18next";
import Filters from "components/Main/Trends/Filters";
import Legend from "components/Main/Trends/Legend";
import Chart from "components/Main/Trends/Chart";
import { useAfricanYouth } from "context/africanYouth";
import Loader from "components/Loader";
import NoData from "components/NoData";
import ShareBtn from "components/Share";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const Trends = () => {
    const {
        country,
        disaggregated,
        status,
        ages,
        gender,
        trendsEmployedSector
    } = useAfricanYouth();
    const { isDevice } = useWindowResize();

    const { data, isLoading, hasError } = useFetch(
        `${urls.API_HOST_URL}trends?countryCode=${getCountryId(
            country.trends_section || ""
        )}&ageFrom=${ages.from || 15}&ageTo=${ages.to || 35}${
            gender !== "all" ? `&gender=${getGender(gender)}` : ""
        }${
            disaggregated
                ? `&disaggregatedBy=${getDisaggregated(disaggregated)}`
                : ""
        }${status ? `&sector=${status.toLocaleUpperCase()}` : ""}${
            trendsEmployedSector &&
            ["Kenya", "Rwanda", "Ghana"].includes(country.trends_section!)
                ? `&sectorGroup=${
                      trendsEmployedSector.charAt(0).toUpperCase() +
                      trendsEmployedSector.slice(1)
                  }`
                : ""
        }`,
        !country.trends_section || !status
    );

    return (
        <section
            id="Trends"
            tw="bg-white p-[0 4rem 5rem] sm:mb-8 sm:p-0 border-t md:pb-8 border-t-grey-200 relative"
        >
            <div tw="border-b border-b-grey-200 sm:border-none sm:mb-[1rem] mb-[2rem] flex justify-between gap-[1rem] items-center md:flex-col md:m-0 md:items-start p-[2rem 0.5rem 1rem] sm:pr-[1rem] sm:px-[1rem]">
                <div tw="flex flex-col gap-[1rem]">
                    <h2 tw="font-semiBold text-xl text-grey-600">
                        {t("trends_title")}
                    </h2>
                    <p tw="text-grey-500 text-sm">{t("trends_subtitle")}</p>
                </div>
                {country.sectors_section && !isDevice && (
                    <ShareBtn
                        section="Trends"
                        country={country.sectors_section!}
                        disaggregated={disaggregated}
                        gender={gender}
                        status={status}
                        sector={trendsEmployedSector}
                        ages={{ from: ages.from!, to: ages.to! }}
                    />
                )}
            </div>
            <Filters />
            {!country.trends_section || !status ? null : isLoading ? (
                <Loader />
            ) : data?.length > 0 && !hasError ? (
                <div tw="border border-grey-200 rounded-[0.5rem] lg:border-none p-[1rem] sm:mt-0 mt-[1rem] sm:p-[1rem 0 1rem 1rem] flex flex-col">
                    <p tw="text-grey-800 text-[1.063rem] pb-[0.5rem] font-medium">
                        {!disaggregated && !trendsEmployedSector
                            ? `Total ${t(`${status}.title`)}`
                            : t(
                                  disaggregated
                                      ? `${status}.trends.title${
                                            status === "Employed" ||
                                            status === "Unemployed"
                                                ? `_${disaggregated}`
                                                : ""
                                        }`
                                      : `${status}.title`
                              )}
                    </p>
                    <p tw="text-sm pb-[1rem] text-grey-400">
                        {t(
                            `${status}.trends.subTitle${
                                status === "Employed" &&
                                disaggregated === "jobs"
                                    ? `_jobs`
                                    : ""
                            }`
                        )}
                    </p>
                    <div
                        tw="items-center gap-[1.5rem] sm:overflow-x-scroll flex md:flex-wrap md:flex-col md:items-start md:pb-[1.5rem]"
                        className="scrollable"
                    >
                        <Chart chartData={data} />
                        <Legend />
                    </div>
                </div>
            ) : (
                <NoData />
            )}
            {country.sectors_section && isDevice && (
                <ShareBtn
                    section="Trends"
                    country={country.sectors_section!}
                    disaggregated={disaggregated}
                    gender={gender}
                    status={status}
                    sector={trendsEmployedSector}
                    ages={{ from: ages.from!, to: ages.to! }}
                />
            )}
        </section>
    );
};

export default Trends;
