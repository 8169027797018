import useWindowResize from "hooks/useWindowResize";
import useAutocomplete from "hooks/useAutocomplete";
import { t } from "i18next";
import { useAfricanYouth } from "context/africanYouth";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles } from "utils/autoComplete";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { statusList, countries, sectors } from "utils/lists";
import { useMemo } from "react";
import useToggle from "hooks/useToggle";
import Warning from "components/Warning";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const Filters = () => {
  const {
    country,
    mobileModalDropdown,
    setMobileModalDropdown,
    status,
    ages,
    gender,
    setFilters,
    disaggregated,
    trendsEmployedSector,
  } = useAfricanYouth();
  const { handleAutocompleteChange } = useAutocomplete();
  const { toggleState } = useToggle();
  const { isDesktop } = useWindowResize();

  const memoCountryInput = useMemo(
    () => (
      <Autocomplete
        readOnly={isDesktop ? false : true}
        clearIcon={<ClearIcon />}
        autoHighlight
        options={countries}
        onChange={(_, value, reason) =>
          handleAutocompleteChange(value?.name!, reason, "trends_section")
        }
        value={
          country.trends_section
            ? { name: country.trends_section }
            : (null as any)
        }
        sx={AutoCompleteStyles}
        tw="w-full"
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: Country) => (
          <button
            {...props}
            style={{
              color: theme`colors.grey.700`,
              display: "flex",
              fontSize: "0.8rem",
              fontWeight: 700,
              gap: "0.65rem",
              width: "100%",
              textAlign: "left",
            }}>
            {option.file_url ? (
              <img
                loading="lazy"
                width="16"
                src={option.file_url}
                alt={option.name}
              />
            ) : (
              <span style={{ width: "1rem" }}></span>
            )}
            {option.name}
          </button>
        )}
        renderInput={(params: any) => {
          const activeCountryFlag = countries.find(
            ({ name }) => name === params.inputProps.value
          );

          return (
            <div
              tw="flex items-center relative"
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "countries/trends_section",
                    })
              }>
              {activeCountryFlag && (
                <img
                  key={params.inputProps.value + "'s flag"}
                  loading="lazy"
                  width="24"
                  src={activeCountryFlag.file_url}
                  alt="Country flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                  input: {
                    "&::placeholder": {
                      color: "#ADADAD !important",
                    },
                  },
                }}
                placeholder={t("type_or_select_a_region_placeholder")}
              />
            </div>
          );
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country.trends_section, isDesktop]
  );

  const memoStatusInput = useMemo(
    () => (
      <div tw="flex flex-col w-full">
        <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
          {t("status.title")}
        </label>
        <Autocomplete
          readOnly={isDesktop ? false : true}
          clearIcon={<ClearIcon />}
          autoHighlight
          options={statusList}
          onChange={(_, value, reason) => {
            handleAutocompleteChange(value?.name!, reason, "status");
            handleAutocompleteChange("", "clear", "disaggregated");
            handleAutocompleteChange("", "clear", "trendsEmployedSector");
          }}
          value={status ? { name: t(`${status}.title`) } : (null as any)}
          sx={AutoCompleteStyles}
          getOptionLabel={(option) => option.name}
          renderOption={(props: any, option) => (
            <button
              {...props}
              style={{
                color: theme`colors.grey.700`,
                display: "flex",
                fontSize: "0.8rem",
                fontWeight: 700,
                gap: "0.65rem",
                width: "100%",
                textAlign: "left",
              }}>
              <img
                loading="lazy"
                width="16"
                src={`assets/images/status/${t(`${option.name}.title`)}.svg`}
                alt={t(`${option.name}.title`)!}
              />
              {t(`${option.name}.title`)}
            </button>
          )}
          renderInput={(params: any) => (
            <div
              onClick={() =>
                isDesktop
                  ? undefined
                  : setMobileModalDropdown({
                      value: !mobileModalDropdown.isMobileModalDropdownOpen,
                      name: "mobileModalDropdown",
                      section: "status",
                    })
              }
              tw="flex justify-between items-center relative">
              {status && (
                <img
                  loading="lazy"
                  width="24"
                  src={`assets/images/status/${t(`${status}.title`)}.svg`}
                  alt="Status flag"
                  tw="absolute left-[0.5rem] z-10"
                />
              )}
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                sx={{
                  textAlign: "left",
                  paddingLeft: params.inputProps.value ? "2rem" : 0,
                }}
                placeholder={t("status.title")}
              />
            </div>
          )}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, isDesktop]
  );

  return (
    <article tw="p-[1rem 0] sm:p-[0 1rem 2rem] sm:m-0">
      <div
        css={[
          tw`flex justify-start gap-[1rem] items-end md:flex-col md:items-start w-full mt-[1rem]`,
          toggleState.areTrendsSubNationalsActive && tw`xl:flex-col`,
        ]}>
        <div tw="w-full">
          <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
            {t("region")}
          </label>
          <div tw="flex gap-2 items-center">
            {memoCountryInput}
            <Warning countryName={country.trends_section!} />
          </div>
        </div>
        <div tw="flex gap-[1rem] w-full sm:flex-wrap">{memoStatusInput}</div>
        {["Employed"].includes(status) ? (
          <div tw="flex gap-[1rem] w-full sm:flex-wrap">
            <div tw="flex flex-col w-full">
              <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
                {t("disaggregated_by.title")}
              </label>
              <div tw="w-full">
                <Autocomplete
                  readOnly={isDesktop ? false : true}
                  clearIcon={<ClearIcon />}
                  onChange={(_, value, reason) => {
                    handleAutocompleteChange(
                      value?.name!,
                      reason,
                      "disaggregated"
                    );
                    handleAutocompleteChange(
                      "",
                      "clear",
                      "trendsEmployedSector"
                    );
                  }}
                  value={
                    disaggregated ? { name: t(disaggregated) } : (null as any)
                  }
                  sx={AutoCompleteStyles}
                  options={
                    statusList.find(({ name }) => name === "Employed")?.trends!
                  }
                  autoHighlight
                  getOptionLabel={(option: any) => option.name}
                  renderOption={(props: any, option: any) => (
                    <button
                      {...props}
                      style={{
                        color: theme`colors.grey.700`,
                        display: "flex",
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        width: "100%",
                      }}>
                      {t(option.name)}
                    </button>
                  )}
                  renderInput={(params: any) => {
                    const placeholder = t(
                      "type_or_select_a_disaggregation_placeholder"
                    ) as string;

                    return (
                      <div
                        onClick={() =>
                          isDesktop
                            ? undefined
                            : setMobileModalDropdown({
                                value:
                                  !mobileModalDropdown.isMobileModalDropdownOpen,
                                name: "mobileModalDropdown",
                                section: "disaggregated",
                              })
                        }>
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            placeholder,
                          }}
                          sx={{
                            input: {
                              "&::placeholder": {
                                color: "#ADADAD !important",
                              },
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ["Unemployed"].includes(status) && (
            <div tw="flex gap-[1rem] w-full sm:flex-wrap">
              <div tw="flex flex-col w-full">
                <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
                  {t("disaggregated_by.title")}
                </label>
                <div tw="w-full">
                  <Autocomplete
                    readOnly={isDesktop ? false : true}
                    clearIcon={<ClearIcon />}
                    onChange={(_, value, reason) => {
                      handleAutocompleteChange(
                        value?.name!,
                        reason,
                        "disaggregated"
                      );
                      handleAutocompleteChange(
                        "",
                        "clear",
                        "trendsEmployedSector"
                      );
                    }}
                    value={
                      disaggregated ? { name: t(disaggregated) } : (null as any)
                    }
                    sx={AutoCompleteStyles}
                    options={
                      statusList.find(({ name }) => name === "Unemployed")
                        ?.trends!
                    }
                    autoHighlight
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props: any, option: any) => (
                      <button
                        {...props}
                        id={option.name}
                        name={option.name}
                        style={{
                          color: theme`colors.grey.700`,
                          display: "flex",
                          fontSize: "0.8rem",
                          fontWeight: 700,
                          width: "100%",
                        }}>
                        {t(option.name)}
                      </button>
                    )}
                    renderInput={(params: any) => {
                      const placeholder = t(
                        "type_or_select_a_disaggregation_placeholder"
                      ) as string;

                      return (
                        <div
                          onClick={() =>
                            isDesktop
                              ? undefined
                              : setMobileModalDropdown({
                                  value:
                                    !mobileModalDropdown.isMobileModalDropdownOpen,
                                  name: "mobileModalDropdown",
                                  section: "disaggregated",
                                })
                          }>
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              placeholder,
                            }}
                            sx={{
                              input: {
                                "&::placeholder": {
                                  color: "#ADADAD !important",
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )
        )}
        {status === "Employed" &&
          disaggregated === "educational" &&
          ["Kenya", "Rwanda", "Ghana"].includes(country.trends_section!) && (
            <div tw="flex gap-[1rem] w-full sm:flex-wrap">
              <div tw="flex flex-col w-full">
                <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
                  {t("educational_level_sector.title")}
                </label>
                <div tw="w-full">
                  <Autocomplete
                    readOnly={isDesktop ? false : true}
                    clearIcon={<ClearIcon />}
                    onChange={(_, value, reason) => {
                      handleAutocompleteChange(
                        value?.name!,
                        reason,
                        "trendsEmployedSector"
                      );
                      !["Kenya", "Rwanda", "Ghana"].includes(
                        country.trends_section!
                      ) &&
                        handleAutocompleteChange(
                          value?.name!,
                          "clear",
                          "disaggregated"
                        );
                    }}
                    value={
                      trendsEmployedSector
                        ? {
                            name: t(`${trendsEmployedSector}.title`),
                          }
                        : (null as any)
                    }
                    sx={AutoCompleteStyles}
                    options={sectors}
                    autoHighlight
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props: any, option: any) => (
                      <button
                        {...props}
                        style={{
                          color: theme`colors.grey.700`,
                          display: "flex",
                          fontSize: "0.8rem",
                          alignItems: "center",
                          fontWeight: 700,
                          width: "100%",
                          gap: "1rem",
                          textAlign: "left",
                        }}>
                        <img
                          loading="lazy"
                          width="16"
                          src={option.file_url}
                          alt={t(`${option.name}.title`)!}
                        />
                        {t(`${option.name}.title`)}
                      </button>
                    )}
                    renderInput={(params: any) => {
                      const placeholder = t(
                        "type_or_select_a_sector_placeholder"
                      ) as string;

                      return (
                        <div
                          tw="flex justify-between items-center relative"
                          onClick={() =>
                            isDesktop
                              ? undefined
                              : setMobileModalDropdown({
                                  value:
                                    !mobileModalDropdown.isMobileModalDropdownOpen,
                                  name: "mobileModalDropdown",
                                  section: "trendsEmployedSector",
                                })
                          }>
                          {trendsEmployedSector && (
                            <img
                              loading="lazy"
                              width="24"
                              src={`assets/images/sectors/${trendsEmployedSector}.svg`}
                              alt="Sector flag"
                              tw="absolute left-[0.5rem] z-10"
                            />
                          )}
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              placeholder,
                            }}
                            sx={{
                              paddingLeft: params.inputProps.value ? "2rem" : 0,
                              input: {
                                "&::placeholder": {
                                  color: "#ADADAD !important",
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        <div tw="flex flex-col gap-[1rem] w-full sm:flex-wrap sm:gap-0">
          <label tw="text-grey-500 leading-[0.938rem] text-sm pb-[0.625rem]">
            {t("filters")}
          </label>
          <div tw="flex gap-[1rem]">
            <button
              onClick={() => {
                setFilters({
                  value: true,
                  name: "isFiltersDialogOpen",
                });
                setFilters({
                  value: ["AGE", "GENDER"],
                  name: "filterListForSection",
                });
              }}
              tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
              {`${ages.from ? ages.from : 15}-${ages.to ? ages.to : 35} years`}
            </button>
            <button
              onClick={() => {
                setFilters({
                  value: true,
                  name: "isFiltersDialogOpen",
                });
                setFilters({
                  value: ["AGE", "GENDER"],
                  name: "filterListForSection",
                });
              }}
              tw="bg-orange-600 hover:bg-orange-700 p-[0.25rem 0.5rem] text-sm text-white font-medium rounded-[1rem]">
              {t(gender)}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Filters;
