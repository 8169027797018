import { statusList } from "utils/lists";
import { GroupByEducation, TrendsListEntry } from "types/trends.types";
import { t } from "i18next";
import { theme } from "twin.macro";
import { currentYear } from "./utilities";

export const getActiveTrendsList = (status: string, disaggregated: string) =>
    status === "Employed" || status === "Unemployed"
        ? statusList
              .find(({ name }) => status === name)
              ?.trends?.find(({ name }) => name === disaggregated)?.list
        : statusList.find(({ name }) => name === status)?.trends;

export const buildChart = (
    trends: TrendsListEntry[],
    status: string,
    disaggregated: string,
    trendsEmployedSector: string
) => {
    const result = groupByEducation(
        trends,
        !disaggregated && !trendsEmployedSector
    );
    const activeTrendsList: {
        id: string;
        name: string;
        color?: string;
    }[] = getActiveTrendsList(status, disaggregated) || [];

    const charData = Object.entries(result).map(([key, value]) => {
        const keyStr = key.split("_")[0];
        const currentPoint = activeTrendsList.find(
            (r) => r.id.toLocaleLowerCase() === keyStr.toLocaleLowerCase()
        );
        const name = currentPoint?.name
            ? t(`${status}.trends.list.${currentPoint?.name}`)
            : t(`${trendsEmployedSector}.title`);

        return {
            name:
                !disaggregated && !trendsEmployedSector
                    ? `Total ${t(`${status}.title`)}`
                    : name,
            color: key.includes("projection")
                ? //Fill pattern for projections
                  {
                      pattern: {
                          backgroundColor:
                              currentPoint?.color ||
                              statusList.find(({ name }) => name === status)
                                  ?.color ||
                              theme`colors.green.400`,
                          path: {
                              d: "M 10 0 L 0 10 M 1 -1 L -1 1 M 11 9 L 9 11"
                          },
                          width: 10,
                          height: 10,
                          opacity: 1,
                          color: "rgba(0,0,0,0.5)"
                      }
                  }
                : currentPoint?.color ||
                  statusList.find(({ name }) => name === status)?.color ||
                  theme`colors.green.400`,
            zoneAxis: "x",
            zones: [
                {
                    value: currentYear,
                    color:
                        activeTrendsList.find(
                            (r) =>
                                r.id.toLocaleLowerCase() ===
                                keyStr.toLocaleLowerCase()
                        )?.color ||
                        statusList.find(({ name }) => name === status)?.color ||
                        theme`colors.green.400`
                },
                {
                    value: 2030,
                    color: {
                        pattern: {
                            backgroundColor:
                                activeTrendsList.find(
                                    (r) =>
                                        r.id.toLocaleLowerCase() ===
                                        keyStr.toLocaleLowerCase()
                                )?.color ||
                                statusList.find(({ name }) => name === status)
                                    ?.color ||
                                theme`colors.green.400`,
                            path: {
                                d: "M 10 0 L 0 10 M 1 -1 L -1 1 M 11 9 L 9 11"
                            },
                            width: 10,
                            height: 10,
                            opacity: 1,
                            color: "rgba(0,0,0,0.5)"
                        }
                    }
                }
            ],
            data:
                !disaggregated && !trendsEmployedSector
                    ? Object.entries(result).map(
                          ([_, population]: any) => population / 1000000
                      )
                    : [...value.map((v) => v.population / 1000000)]
        };
    });

    return charData;
};

const groupByEducation = (
    trends: TrendsListEntry[],
    groupByStatuses: boolean
): GroupByEducation => {
    if (groupByStatuses)
        return trends.reduce((r, entry) => {
            const { year, population } = entry;
            r[year] = (r[year] || 0) + population;
            return r;
        }, Object.create(null));
    else
        return trends.reduce((r, entry) => {
            const { name } = entry;
            r[name] = r[name] || [];
            r[name].push(entry);
            return r;
        }, Object.create(null));
};
