import arrow from "assets/images/arrowDropdown.svg";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

export const AutoCompleteStyles = {
  "& .MuiAutocomplete-clearIndicator": {
    marginRight: "0 !important",
    background: "white",
    visibility: "visible !important",
  },
  "& .MuiAutocomplete-clearIndicator:hover": {
    backgroundColor: "white",
  },
  "& .MuiAutocomplete-endAdornment": {
    top: "unset !important",
  },
  "& .MuiAutocomplete-input": {
    fontWeight: 400,
    width: "100% !important",
  },
  "& .MuiAutocomplete-option": {
    fontWeight: 400,
  },
  "& .MuiAutocomplete-input::placeholder": {
    opacity: 1,
    color: theme`colors.grey.700`,
    fontFamily: "Work Sans !important",
  },
  "& .MuiAutocomplete-popupIndicator svg": {
    display: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiTextField-root": {
    background: `url(${arrow}) no-repeat 100%`,
    backgroundPosition: "right 1rem center",
    borderBottom: "1px solid #E8842B",
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
    fontSize: "1.063rem",
    lineHeight: "20px",
    paddingRight: "0 !important",
  },
};

export const AutoCompleteStylesHero = {
  "& .MuiTextField-root": {
    background: `url(${arrow}) no-repeat 100%`,
    backgroundPosition: "right 1rem center",
    border: "1px solid #E8842B",
    borderRadius: "2.75rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    paddingRight: "39px !important",
  },
};
