import { Country } from "types/country.types";
import { IdNamePair } from "types/id-pair.types";
import { SliderPair } from "types/slider.types";
import { CompareSituationsProps } from "types/compare-situation.types";
import countriesJson from "data/countries_flags.json";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { theme } from "twin.macro";

export const countries: Country[] = countriesJson[0].data;

export const customColors = [
    theme`colors.green.600`,
    theme`colors.blue.600`,
    theme`colors.yellow.600`,
    theme`colors.red.600`
];

export const colorsPalette = [
    "#458461",
    "#ED9A00",
    "#CF4644",
    "#28B869",
    "#EDB347",
    "#DF7976",
    "#58BF86",
    "#FFC966",
    "#82D3A7",
    "#F9D797",
    "#EE8A88",
    "#C3EED7",
    "#FFE4B3",
    "#FFA594",
    "#EBFFF4",
    "#FFEDCC",
    "#FFBBAD",
    "#FFE6E0"
];

export const shareSocials: IdNamePair[] = [
    { name: "download", id: "" },
    { name: "email", id: "mailto:contact@worlddata.io" },
    { name: "message", id: "tel:number" },
    {
        name: "whatsApp",
        id: "https://wa.me/?text=CONTACT%20DETAILS%20-%20https://tr.ee/anfXgYOeId"
    },
    { name: "telegram", id: "" },
    { name: "instagram", id: "https://www.instagram.com/worlddatalab/" },
    {
        name: "messenger",
        id: "https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Fnew"
    },
    { name: "twitter", id: "https://twitter.com/worlddatalab" },
    { name: "facebook", id: "https://www.facebook.com/worlddatalab" }
];

export const genders: IdNamePair[] = [
    {
        name: "All",
        id: "all"
    },
    {
        name: "Male",
        id: "males"
    },
    {
        name: "Female",
        id: "females"
    }
];

export const areas: IdNamePair[] = [
    {
        name: "both",
        id: "both"
    },
    {
        name: "rural",
        id: "rural"
    },
    {
        name: "urban",
        id: "urban"
    }
];

export const employments: IdNamePair[] = [
    {
        name: "both",
        id: "both"
    },
    {
        name: "informal",
        id: "informal"
    },
    {
        name: "formal",
        id: "formal"
    }
];

export const statusList: IdNamePair[] = [
    {
        name: "Employed",
        id: "employed",
        file_url: "assets/images/status/Employed.svg",
        color: theme`colors.green.600`,
        trends: [
            {
                name: "educational",
                id: "educational",
                list: [
                    {
                        name: "tertiary",
                        id: "tertiary",
                        color: theme`colors.green.600`
                    },
                    {
                        name: "secondary",
                        id: "secondary",
                        color: theme`colors.green.500`
                    },
                    {
                        name: "primary",
                        id: "primary",
                        color: theme`colors.green.300`
                    },
                    {
                        name: "with_no",
                        id: "no",
                        color: theme`colors.green.200`
                    }
                ]
            },
            {
                name: "rural_urban_areas",
                id: "rural_urban_areas",
                list: [
                    {
                        name: "urban",
                        id: "urban",
                        color: theme`colors.green.600`
                    },
                    {
                        name: "rural",
                        id: "rural",
                        color: theme`colors.green.500`
                    }
                ]
            },
            {
                name: "poor",
                id: "poor",
                list: [
                    {
                        name: "not_poor",
                        id: ">= USD 3.20 PPP",
                        color: theme`colors.green.600`
                    },
                    {
                        name: "moderately_poor",
                        id: "Moderately poor >= USD 1.90 and < USD 3.20 PPP",
                        color: theme`colors.green.500`
                    },
                    {
                        name: "extremely_poor",
                        id: "Extremely poor < USD 1.90 PPP",
                        color: theme`colors.green.300`
                    }
                ]
            },
            {
                name: "jobs",
                id: "jobs",
                list: [
                    {
                        name: "formal",
                        id: "formal",
                        color: theme`colors.green.600`
                    },
                    {
                        name: "informal",
                        id: "informal",
                        color: theme`colors.green.500`
                    }
                ]
            }
        ]
    },
    {
        name: "Inactive",
        id: "inactive",
        file_url: "assets/images/status/Inactive.svg",
        color: theme`colors.orange.600`,
        trends: [
            {
                name: "tertiary_education",
                id: "tertiary",
                color: theme`colors.orange.600`
            },
            {
                name: "secondary_education",
                id: "secondary",
                color: theme`colors.orange.500`
            },
            {
                name: "primary_education",
                id: "primary",
                color: theme`colors.orange.300`
            },
            {
                name: "with_no_education",
                id: "no",
                color: theme`colors.orange.100`
            }
        ]
    },
    {
        name: "Unemployed",
        id: "unemployed",
        file_url: "assets/images/status/Unemployed.svg",
        color: theme`colors.red.600`,
        trends: [
            {
                name: "educational",
                id: "educational",
                list: [
                    {
                        name: "tertiary",
                        id: "tertiary",
                        color: theme`colors.red.600`
                    },
                    {
                        name: "secondary",
                        id: "secondary",
                        color: theme`colors.red.400`
                    },
                    {
                        name: "primary",
                        id: "primary",
                        color: theme`colors.red.300`
                    },
                    {
                        name: "with_no",
                        id: "no",
                        color: theme`colors.red.100`
                    }
                ]
            },
            {
                name: "rural_urban_areas",
                id: "rural_urban_areas",
                list: [
                    {
                        name: "urban",
                        id: "urban",
                        color: theme`colors.red.600`
                    },
                    {
                        name: "rural",
                        id: "rural",
                        color: theme`colors.red.400`
                    }
                ]
            }
        ]
    },
    {
        name: "Student",
        id: "student",
        color: theme`colors.blue.600`,
        file_url: "assets/images/status/Student.svg",
        trends: [
            { name: "male_student", id: "male", color: theme`colors.blue.600` },
            {
                name: "female_studen",
                id: "female",
                color: theme`colors.blue.500`
            }
        ]
    }
];

export const sectors: IdNamePair[] = [
    {
        name: "services",
        id: "Services",
        file_url: "assets/images/sectors_smalls/services.svg",
        color: theme`colors.green.600`
    },
    {
        name: "agriculture",
        id: "Agriculture",
        file_url: "assets/images/sectors_smalls/agriculture.svg",
        color: theme`colors.green.500`
    },
    {
        name: "industry",
        id: "Industry",
        file_url: "assets/images/sectors_smalls/industry.svg",
        color: theme`colors.green.300`
    }
];

export const agesRange: SliderPair[] = [
    {
        label: "15",
        value: 15
    },
    {
        label: "20",
        value: 20
    },
    {
        label: "25",
        value: 25
    },
    {
        label: "30",
        value: 30
    },
    {
        label: "35",
        value: 35
    }
];

export const years: SliderPair[] = [
    {
        name: "two_thousand_fifteen",
        label: "2015",
        value: 2015
    },
    {
        name: "two_thousand_sixteen",
        label: "2016",
        value: 2016
    },
    {
        name: "two_thousand_seventeen",
        label: "2017",
        value: 2017
    },
    {
        name: "two_thousand_eighteen",
        label: "2018",
        value: 2018
    },
    {
        name: "two_thousand_nineteen",
        label: "2019",
        value: 2019
    },
    {
        name: "two_thousand_twenty",
        label: "2020",
        value: 2020
    },
    {
        name: "two_thousand_twentyone",
        label: "2021",
        value: 2021
    },
    {
        name: "two_thousand_twentytwo",
        label: "2022",
        value: 2022
    },
    {
        name: "two_thousand_twentythree",
        label: "2023",
        value: 2023
    },
    {
        name: "two_thousand_twentyfour",
        label: "2024",
        value: 2024
    },
    {
        name: "two_thousand_twentyfive",
        label: "2025",
        value: 2025
    },
    {
        name: "two_thousand_twentysix",
        label: "2026",
        value: 2026
    },
    {
        name: "two_thousand_twentyseven",
        label: "2027",
        value: 2027
    },
    {
        name: "two_thousand_twentyeight",
        label: "2028",
        value: 2028
    },
    {
        name: "two_thousand_twentynine",
        label: "2029",
        value: 2029
    },
    {
        name: "two_thousand_thirty",
        label: "2030",
        value: 2030
    }
];

export const languages: IdNamePair[] = [
    {
        name: "english_language",
        id: "eng"
    },
    {
        name: "french_language",
        id: "fra"
    },
    {
        name: "portuguese_language",
        id: "por"
    },
    {
        name: "kiswahili_language",
        id: "kis"
    }
];

export const links: IdNamePair[] = [
    {
        name: "home",
        id: "#home"
    },
    {
        name: "employment",
        id: "#Employment"
    },
    {
        name: "sectors",
        id: "#Genders"
    },
    {
        name: "trends",
        id: "#Trends"
    },
    {
        name: "employment_education",
        id: "#Education"
    },
    {
        name: "country_comparison",
        id: "#CountryComparison"
    },
    {
        name: "about_us_title",
        id: "#methodology"
    },
    {
        name: "help_us_get_better",
        id: "#help_us"
    }
];

export const menu: IdNamePair[] = [
    {
        name: "home",
        id: "#home"
    },
    {
        name: "employment",
        id: "#employment"
    },
    {
        name: "summary",
        id: "#summary"
    },
    {
        name: "trends",
        id: "#trends"
    },
    {
        name: "employment_education",
        id: "#employment_education"
    },
    {
        name: "country_comparison",
        id: "#country_comparison"
    },
    {
        name: "help_us_get_better",
        id: "#help_us_get_better"
    },
    {
        name: "methodology",
        id: "#methodology"
    }
];

export const compareSituations: CompareSituationsProps[] = [
    {
        name: "population",
        open: false,
        country1: {
            id: 0
        },
        country2: {
            id: 0
        }
    },
    {
        name: "employed",
        open: true,
        country1: {
            id: 1
        },
        country2: {
            id: 1
        },
        openSituations: []
    },
    {
        name: "working_poverty",
        open: true,
        country1: {
            id: 2
        },
        country2: {
            id: 2
        },
        openSituations: []
    },
    {
        name: "formal_jobs",
        open: false,
        country1: {
            id: 3
        },
        country2: {
            id: 3
        }
    },
    {
        name: "student",
        open: false,
        country1: {
            id: 4
        },
        country2: {
            id: 4
        }
    },
    {
        name: "inactive",
        open: false,
        country1: {
            id: 5
        },
        country2: {
            id: 5
        }
    },
    {
        name: "unemployed",
        open: false,
        country1: {
            id: 6
        },
        country2: {
            id: 6,
            number: 401356
        }
    },
    {
        name: "urban_areas",
        open: false,
        country1: {
            id: 7
        },
        country2: {
            id: 7
        }
    }
];
