import { MouseEvent, useCallback, useState } from "react";

interface IdKey {
    id: string;
}

interface MenuTypes {
    dropdown: boolean;
}

interface EmploymentTypes {
    areMapSectorsOpened: boolean;
}

interface SectorGender {
    males: boolean;
    females: boolean;
    areInnerSectorOpened: boolean;
    open: boolean;
}

interface Navbar {
    isNavbarOpened: boolean;
}

interface Subnationals {
    areTrendsSubNationalsActive: boolean;
    areMapSubNationalsActive: boolean;
    areGendersSubNationalsActive: boolean;
}

interface Metholody {
    isMethodologyArticleSelected: boolean;
}

interface Breakdown {
    isInnerSectionBreakdownOpen: boolean;
}

interface Comparison {
    employed_comparison: boolean;
    working_poverty_comparison: boolean;
}

interface InnerSituations {
    ispoorDropdownOpen: boolean;
    isjobsDropdownOpen: boolean;
    isurban_areasDropdownOpen: boolean;
}

interface ShareModal {
    isShareModalVertical: boolean;
    isWidgetActive: boolean;
}

type CombinedTypes = IdKey &
    Subnationals &
    MenuTypes &
    EmploymentTypes &
    SectorGender &
    Navbar &
    Metholody &
    Breakdown &
    Comparison &
    InnerSituations &
    ShareModal;

const useToggle = () => {
    const [toggleState, setToggleState] = useState<CombinedTypes>({
        id: "",
        areTrendsSubNationalsActive: false,
        areMapSubNationalsActive: false,
        areGendersSubNationalsActive: false,
        ispoorDropdownOpen: false,
        isjobsDropdownOpen: false,
        isurban_areasDropdownOpen: false,
        areMapSectorsOpened: false,
        dropdown: false,
        males: false,
        females: false,
        isInnerSectionBreakdownOpen: false,
        employed_comparison: false,
        isMethodologyArticleSelected: false,
        isNavbarOpened: false,
        areInnerSectorOpened: false,
        open: false,
        working_poverty_comparison: false,
        isShareModalVertical: false,
        isWidgetActive: false
    });

    const handleToggleState = useCallback(
        (toggleType: string, e?: MouseEvent) => {
            if (e) e.stopPropagation();
            setToggleState((prevState) => {
                const key = toggleType as string as keyof typeof toggleState;
                return { ...toggleState, [toggleType]: !prevState[key] };
            });
        },
        [toggleState, setToggleState]
    );

    return { handleToggleState, toggleState };
};

export default useToggle;
